import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import format from "date-fns/format";
import Utils from "quilt/lib/utils";
import RehypeReact from "rehype-react";
import CodeBlock from "quilt/lib/components/atoms/code-block";
import Table from "quilt/lib/components/atoms/table";
import Image from "quilt/lib/components/atoms/image";
import SEO from "../components/functional/seo";

import Frame from "../components/atoms/frame";
import Auth from "../components/molecules/auth";
import StoreContext from "../context/storeContext";

const useAuth =
  process.env.ENABLE_AUTH && process.env.ENABLE_AUTH.toLowerCase() === "true";

const DocsTemplate = ({ pageContext }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: { codeblock: CodeBlock, table: Table, iframe: Frame },
  }).Compiler;

  const {
    store: { user },
  } = useContext(StoreContext);
  const displayAuth = useAuth && !user;

  const pageProps = pageContext.page;
  const pageText = pageContext.page.content && pageContext.page.content.content;
  const pageHtml =
    pageContext.page.content &&
    pageContext.page.content.childMarkdownRemark.html;
  const pageHtmlAst =
    pageContext.page.content &&
    pageContext.page.content.childMarkdownRemark.htmlAst;

  let textContent = null;
  if (pageText && Utils.isMarkdown(pageText, pageHtml)) {
    textContent = <div className="design__html">{renderAst(pageHtmlAst)}</div>;
  } else if (pageText) {
    textContent = <p className="design__text p2">{pageText}</p>;
  }
  if (displayAuth) {
    return <Auth useSignup={false} title="Sign In" providers={["google"]} />;
  }

  return (
    <div className="design__content design__content--search">
      <SEO title={pageProps.title} />
      <p className="microtext">{site.siteMetadata.title}</p>
      <h2 className="design__title">{pageProps.title}</h2>
      {textContent}
      {pageContext.page.media ? (
        <div id="docs-media">
          {pageContext.page.media.map((media, index) => {
            if (media.file.contentType.includes("image/")) {
              return (
                <React.Fragment key={media.id}>
                  <h6 className="bold">
                    {media.description || media.title || `Image ${index + 1}`}
                  </h6>
                  <p className="microtext">{media.file.contentType}</p>
                  <a
                    href={media.file.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Image
                      image={media}
                      alt={media.description || media.title || "Image"}
                      loading="eager"
                    />
                  </a>
                </React.Fragment>
              );
            }
            if (media.file.contentType.includes("video/")) {
              return (
                <React.Fragment key={media.id}>
                  <h6 className="bold">
                    {media.description || media.title || `Image ${index + 1}`}
                  </h6>
                  <p className="microtext">{media.file.contentType}</p>
                  <video playsInline preload="auto" controls>
                    <source src={media.file.url} />
                    <track
                      src="/sample.vtt"
                      kind="captions"
                      srcLang="en"
                      label="English"
                    />
                  </video>
                </React.Fragment>
              );
            }
            return false;
          })}
        </div>
      ) : null}

      <p className="design__text microtext">
        Last updated:{" "}
        {format(new Date(pageContext.page.updatedAt), "MM-dd-yyyy")}
      </p>
    </div>
  );
};

DocsTemplate.propTypes = {
  pageContext: PropTypes.shape({
    design: PropTypes.string,
    page: PropTypes.objectOf(PropTypes.any),
    id: PropTypes.string,
    props: PropTypes.arrayOf(PropTypes.any),
  }),
};

DocsTemplate.defaultProps = {
  pageContext: { layout: "page", id: "index", props: [], page: {} },
};

export default DocsTemplate;
