import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Helmet } from "react-helmet";

import Utils from "quilt/lib/utils";
import Form from "quilt/lib/components/molecules/form";
import Input from "quilt/lib/components/atoms/input";
import Button from "quilt/lib/components/atoms/button";

import GoogleLogo from "../atoms/icons/google-logo";
import StoreContext from "../../context/storeContext";
import RobotoMedium from "../../fonts/Roboto-Medium.ttf";

const Auth = ({
  color,
  title,
  submitTitle,
  providers,
  toggleTitle,
  toggleCta,
  useSignup,
  signupColor,
  signupTitle,
  signupSubmitTitle,
  signupProviders,
  signupToggleTitle,
  signupToggleCta,
}) => {
  const {
    store: { user, auth, authInit, provider },
    updateUser,
    updateAuthInit,
  } = useContext(StoreContext);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [formMessage, setFormMessage] = useState({
    error: false,
    message: "",
  });
  const [mode, setMode] = useState("login");

  const resetForm = () => {
    setEmail();
    setPassword();
    setFormMessage({
      error: false,
      message: "",
    });
  };
  const toggleForgotPassword = async (e) => {
    e.preventDefault();
    setMode("reset");
    resetForm();
  };

  const googleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // console.log("auth error", errorCode, errorMessage);
    }
  };

  const toggleSignIn = async (e) => {
    e.preventDefault();
    if (!user) {
      if (!Utils.emailIsValid(email)) {
        setFormMessage({
          error: true,
          message: "Please enter a valid email.",
        });
      } else if (mode !== "reset" && mode !== "resetSuccess" && !password) {
        setFormMessage({
          error: true,
          message: "Please enter a password.",
        });
      } else if (mode === "login") {
        try {
          const credentials = await signInWithEmailAndPassword(
            auth,
            email,
            password,
          );
          updateUser(credentials.user);
        } catch (error) {
          const errorCode = error.code;
          // const errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            setFormMessage({
              error: true,
              message: "Password is incorrect. Try again.",
            });
          } else if (errorCode === "auth/user-not-found") {
            setFormMessage({
              error: true,
              message: "User not found. Try again.",
            });
          }
          // console.log("auth error", errorCode, errorMessage);
        }
      } else if (mode === "signup") {
        try {
          const credentials = await createUserWithEmailAndPassword(
            auth,
            email,
            password,
          );
          updateUser(credentials.user);
        } catch (error) {
          const errorCode = error.code;
          // const errorMessage = error.message;
          if (errorCode === "auth/weak-password") {
            setFormMessage({
              error: true,
              message: "Please choose a stronger password.",
            });
          }
          // console.log("auth error", errorCode, errorMessage);
        }
      } else if (mode === "reset" || mode === "resetSuccess") {
        try {
          setFormMessage({
            error: false,
            message: "",
          });
          await sendPasswordResetEmail(auth, email);
          // Change mode to reset success
          setMode("resetSuccess");
        } catch (error) {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // console.log("auth error", errorCode, errorMessage);
        }
      }
    }
  };

  const toggleAuthMode = (e) => {
    e.preventDefault();
    setMode(mode === "login" ? "signup" : "login");
    resetForm();
  };
  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        updateUser(authUser);
      }
      updateAuthInit(true);
    });
  }, []);

  let formStatus = "";
  if (formMessage.error) {
    formStatus = "error";
  } else if (formMessage.message) {
    formStatus = "success";
  }

  if (!authInit) {
    return null;
  }

  let switchContent = null;
  if (mode === "login" && useSignup) {
    switchContent = (
      <p className="auth-switch auth-switch-signup">
        {signupToggleTitle}
        <button
          onClick={toggleAuthMode}
          onKeyPress={toggleAuthMode}
          type="button"
          className={`shared__color-${signupColor}`}>
          {signupToggleCta}
        </button>
      </p>
    );
  } else if (mode === "signup") {
    switchContent = (
      <p className="auth-switch auth-switch-login">
        {toggleTitle}
        <button
          onClick={toggleAuthMode}
          onKeyPress={toggleAuthMode}
          type="button">
          {toggleCta}
        </button>
      </p>
    );
  } else if (mode === "reset" || mode === "resetSuccess") {
    switchContent = (
      <p className="auth-switch auth-switch-reset">
        <button
          onClick={toggleAuthMode}
          onKeyPress={toggleAuthMode}
          type="button">
          Back to {toggleCta}
        </button>
      </p>
    );
  }

  const hasEmailOnly = mode === "reset";
  const hasEmailPassword =
    (mode === "login" && providers.includes("emailpassword")) ||
    (mode === "signup" && signupProviders.includes("emailpassword"));
  const hasGoogle =
    (mode === "login" && providers.includes("google")) ||
    (mode === "signup" && signupProviders.includes("google"));
  const hasOnlyGoogle =
    (mode === "login" &&
      providers &&
      providers.length === 1 &&
      providers.includes("google")) ||
    (mode === "signup" &&
      signupProviders &&
      signupProviders.length === 1 &&
      signupProviders.includes("google"));
  const hasSubmitOnly =
    hasEmailOnly || hasEmailPassword || mode === "resetSuccess";

  let authLogin = title;
  if (mode === "signup") {
    authLogin = signupTitle;
  } else if (mode === "reset") {
    authLogin = "Forgot Your Password?";
  } else if (mode === "resetSuccess") {
    authLogin = "Check Your Email";
  }
  let authSubmit = submitTitle;
  if (mode === "signup") {
    authSubmit = signupSubmitTitle;
  } else if (mode === "reset") {
    authSubmit = "Continue";
  } else if (mode === "resetSuccess") {
    authSubmit = "Resend Email";
  }

  return (
    <Form
      onSubmit={toggleSignIn}
      label="Auth"
      className={`auth-form auth-form--${mode}`}
      title={authLogin}>
      {mode === "reset" ? (
        <p className="auth-summary">
          Enter your email address and we will send you instructions to reset
          your password.
        </p>
      ) : null}
      {mode === "resetSuccess" ? (
        <p className="auth-summary">
          Please check the email address {email} for instructions to reset your
          password.
        </p>
      ) : null}
      {hasEmailOnly || hasEmailPassword ? (
        <>
          <Input
            placeholder="Email Address"
            title="Email Address"
            type="text"
            name="email"
            id="authEmail"
            color={color}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {hasEmailPassword ? (
            <>
              <Input
                placeholder="Password"
                title="Password"
                type="password"
                name="password"
                id="authPassword"
                color={color}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {mode === "login" ? (
                <p className="auth-switch auth-switch-login-reset">
                  <button
                    className="reset"
                    onClick={toggleForgotPassword}
                    onKeyPress={toggleForgotPassword}
                    type="button">
                    Forgot password?
                  </button>
                </p>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {hasSubmitOnly ? (
        <Button color={color} submit>
          {authSubmit}
        </Button>
      ) : null}

      {formStatus === "error" ? (
        <p className="microtext bold form__email-error">
          {formMessage.message}
        </p>
      ) : null}
      {formStatus === "success" ? (
        <p className="microtext bold form__email-success">
          {formMessage.message}
        </p>
      ) : null}
      {switchContent}

      {mode !== "reset" && mode !== "resetSuccess" && !hasOnlyGoogle ? (
        <div className="auth-separator">
          <span>or</span>
        </div>
      ) : null}
      {hasGoogle ? (
        <>
          <Helmet>
            <link
              rel="preload"
              href={RobotoMedium}
              as="font"
              crossOrigin="anonymous"
              type="font/ttf"
            />
          </Helmet>
          <div
            className="google-auth-button"
            onClick={googleSignIn}
            onKeyPress={googleSignIn}
            role="button"
            tabIndex={0}>
            <GoogleLogo width={18} />
            <p>Sign in with Google</p>
          </div>
        </>
      ) : null}
    </Form>
  );
};

Auth.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  submitTitle: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string),
  toggleTitle: PropTypes.string,
  toggleCta: PropTypes.string,
  useSignup: PropTypes.bool,
  signupColor: PropTypes.string,
  signupTitle: PropTypes.string,
  signupSubmitTitle: PropTypes.string,
  signupProviders: PropTypes.arrayOf(PropTypes.string),
  signupToggleTitle: PropTypes.string,
  signupToggleCta: PropTypes.string,
};

Auth.defaultProps = {
  color: "eucalyptus",
  title: "Log In",
  submitTitle: "Continue",
  providers: ["emailpassword", "google"],
  toggleTitle: "Already have an account?",
  toggleCta: "Log in",
  useSignup: true,
  signupColor: "eucalyptus",
  signupTitle: "Sign Up",
  signupSubmitTitle: "Continue",
  signupProviders: ["emailpassword", "google"],
  signupToggleTitle: "Don't have an account?",
  signupToggleCta: "Sign up",
};

export default Auth;
